import { useEffect, useRef } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useEvent } from "hooks";
import {
  findBetsSlipSourceType,
  toggleSelection,
} from "sections/Betting/Betslip/betslipSlice";
import { isMarketOpenForBets } from "utilities/markets";
import { useSRMSelectionToggle } from "hooks/useSRMSelectionToggle";
import { useSGMSelectionToggle } from "hooks/useSGMSelectionToggle";

import type { BetslipEventType } from "sections/Betting/Betslip/betslipSlice";
import type { PickType } from "sections/Entries/types";
import { getSubSelectionsFromOutcomes } from "utilities/sem";

export const sourceFromEventType = (type: string) => type as BetslipEventType;

export const useAutoAddPickToBetslip = (
  pick: PickType,
  addToSlip = true,
  onComplete?: () => void,
) => {
  const [event] = useEvent(pick.eventId);
  const hasBeenAdded = useRef(false);
  const dispatch = useDispatch();

  const toggleSRMSelection = useSRMSelectionToggle(pick);
  const toggleSGMSelection = useSGMSelectionToggle(pick);

  useEffect(() => {
    if (!addToSlip || hasBeenAdded.current) return;
    if (!event) return;

    if (pick.marketStatus && isMarketOpenForBets(pick.marketStatus)) {
      const isExotic = !!pick.exoticCombinations;
      const subSelections =
        isExotic && getSubSelectionsFromOutcomes(pick.outcomes);

      // we only need to enforce subSelections in this singles case if we are
      // considering a bet on an exotic market
      if (isExotic && subSelections.length <= 1) return;

      dispatch(
        toggleSelection(
          event,
          {
            ...pick,
            sourceId: pick.eventId,
            type: findBetsSlipSourceType(sourceFromEventType(pick.eventType)),
            ...(isExotic && { subSelections }),
          },
          event.hub,
        ),
      );
      onComplete?.();
    } else if (!isEmpty(pick.outcomes) && Boolean(toggleSRMSelection)) {
      dispatch(toggleSRMSelection);
      onComplete?.();
    } else if (!isEmpty(pick.outcomes) && Boolean(toggleSGMSelection)) {
      dispatch(toggleSGMSelection);
      onComplete?.();
    }

    hasBeenAdded.current = true;
  }, [addToSlip, event, hasBeenAdded.current, pick.eventId]);
};
