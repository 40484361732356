import React, { useContext } from "react";
import { Button } from "components/Button";
import { Dialog } from "components/Dialog";
import { Sheet } from "components/Sheet";

import { FirebaseContext } from "context/Firebase";
import type { BetEntry } from "sections/Entries/types";

import { useShareEntryHandler } from "./useShareEntryHandler";
import { ShareEntryForm } from "./ShareEntryForm";

import * as styles from "./ShareEntryForm.module.scss";
import { useDisplayAtMost } from "hooks/useDisplay";

export type ShareEntryActionProps = {
  className?: string;
  referenceId: string;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  icon?: React.ReactNode;
  entry?: [BetEntry, boolean];
};

export const ShareEntryAction = ({
  referenceId,
  className,
  icon,
  isOpen,
  setOpen,
  entry,
}: ShareEntryActionProps) => {
  const { profile } = useContext(FirebaseContext);

  const [handleSubmit, needsSecondSubmit, hasUrl, clearUrl] =
    useShareEntryHandler(referenceId, () => setOpen(false));

  const onFieldTouched = () => {
    if (hasUrl) clearUrl();
  };

  const title = "Share your bet";
  const description = "Your bet will appear as below";
  const handleClose = () => setOpen(false);
  const isAtMostTablet = useDisplayAtMost("tablet");
  const Component = isAtMostTablet ? Sheet : Dialog;
  const componentClassNames = isAtMostTablet ? styles.sheet : styles.dialog;

  const content = (
    <ShareEntryForm
      entry={entry}
      username={profile?.username}
      onSubmit={handleSubmit}
      onFieldTouched={onFieldTouched}
      submitButton={(isSubmitting: boolean) =>
        needsSecondSubmit ? (
          <Button
            type="submit"
            loading={isSubmitting}
            variant={hasUrl ? "primary" : "info"}
            className={styles.button}
            size="md"
          >
            {hasUrl ? "Copy Link" : "Get Share URL"}
          </Button>
        ) : (
          <Button
            type="submit"
            loading={isSubmitting}
            className={styles.button}
            size="md"
          >
            Share
          </Button>
        )
      }
    />
  );

  return (
    <>
      {icon && (
        <div className={className} onClick={() => setOpen(true)}>
          {icon}
        </div>
      )}

      {/* useDisplay to switch between dialog and sheet */}
      <Component
        isOpen={isOpen}
        title={title}
        description={description}
        onClose={handleClose}
        className={componentClassNames}
      >
        {content}
      </Component>
    </>
  );
};
