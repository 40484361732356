// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clamp = "BetPickAutomation-module--clamp--65507";
export var clampItem = "BetPickAutomation-module--clampItem--a5614";
export var complete = "BetPickAutomation-module--complete--a407e";
export var content = "BetPickAutomation-module--content--1ecfd";
export var dashedLine = "BetPickAutomation-module--dashedLine--b7563";
export var emphasis = "BetPickAutomation-module--emphasis--f0737";
export var green = "#57d33b";
export var greenLink = "BetPickAutomation-module--green-link--a24e8";
export var grey = "var(--tone-mid)";
export var heading = "BetPickAutomation-module--heading--2a93d";
export var isMobile = "BetPickAutomation-module--isMobile--93aef";
export var isWon = "BetPickAutomation-module--isWon--d6244";
export var loading = "BetPickAutomation-module--loading--9d77c";
export var notchGap = "32px";
export var orange = "#f8662b";
export var payout = "BetPickAutomation-module--payout--30d3b";
export var promoIcon = "BetPickAutomation-module--promoIcon--a0f88";
export var promotionContainer = "BetPickAutomation-module--promotionContainer--7d960";
export var red = "#ff2741";
export var skeleton = "BetPickAutomation-module--skeleton--b0c4d";
export var statusArea = "BetPickAutomation-module--statusArea--8fd5a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";