// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var comboCount = "ExoticBetBuilder-module--comboCount--0414c";
export var combos = "ExoticBetBuilder-module--combos--6304a";
export var emphasis = "ExoticBetBuilder-module--emphasis--4f0c0";
export var green = "#57d33b";
export var greenLink = "ExoticBetBuilder-module--green-link--912e5";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";