import {
  type AnnouncementCardProps,
  type AnnouncementCardType,
  useEvent,
  useIsMobile,
  useAnnouncementCards,
} from "hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { cva } from "class-variance-authority";
import cx from "classnames";
import { ErrorBoundary, navigate } from "library";
import { optimizeBackgroundImageUrl as optimizeBackgroundImageUrl } from "utilities/contentful";
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import { AnimatePresence, motion } from "framer-motion";

import * as styles from "./AnnouncementCards.module.scss";
import { PICKLEBET_DOMAINS } from "appConfig";

export const getDifferenceInTime = (scheduledStartTime: Date) => {
  const now = new Date();

  // Calculating the difference in seconds
  const totalSeconds = differenceInSeconds(scheduledStartTime, now);

  // If the difference is less than now (negative), show "Starting Soon"
  if (totalSeconds < 0) {
    return "Starting Soon";
  }

  // If the difference is less than 3 minutes, show in seconds
  if (totalSeconds < 180) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60; // Subtract minutes' worth of seconds
    return `${minutes}m ${seconds}s`;
  }

  // Else, compute hours and minutes difference. Don't show hours if 0
  const totalHours = differenceInHours(scheduledStartTime, now);
  const hours = Math.floor(totalHours);
  const minutes = Math.floor(
    differenceInMinutes(scheduledStartTime, now) - hours * 60,
  );
  return `${hours ? `${hours}h ` : ""}${minutes}m`;
};

const announcementCardVariants = cva(styles.announcementCard, {
  variants: {
    variant: {
      danger: styles.danger,
      warning: styles.warning,
      info: styles.info,
      success: styles.success,
      neutral: styles.neutral,
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

const ContentfulHtmlRenderer = ({ htmlContent }) => (
  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
);

type announcementCardTypeWithCallbacks = Partial<AnnouncementCardType> & {
  noDots?: boolean;
};

export const AnnouncementCard = React.forwardRef<
  HTMLDivElement,
  announcementCardTypeWithCallbacks
>(
  (
    {
      variant,
      title,
      message,
      eventId,
      image,
      actionUrl,
      announcementUrl,

      id,
      backgroundImageMobile,
      noDots,
    },
    ref,
  ) => {
    const [event] = useEvent(eventId);
    const isMobile = useIsMobile();
    const contentRef = useRef(null);

    const [eventTime, setEventTime] = useState(() =>
      event?.scheduledStartTime
        ? getDifferenceInTime(event?.scheduledStartTime)
        : null,
    );

    useEffect(() => {
      let interval;

      if (event) {
        const updateEventTime = () => {
          const newEventTime = getDifferenceInTime(event?.scheduledStartTime);
          setEventTime(newEventTime);
        };

        updateEventTime();

        const timeLeftInSeconds = event?.scheduledStartTime
          ? differenceInSeconds(new Date(event?.scheduledStartTime), new Date())
          : 0;

        if (timeLeftInSeconds < 240) {
          // Less than 3 minutes
          interval = setInterval(updateEventTime, 1000); // Update every second
        } else {
          interval = setInterval(updateEventTime, 60000); // Update every minute
        }
      }

      return () => clearInterval(interval); // Clean up on unmount
    }, [event]);

    const { backgroundImageStyle, hasBackgroundImage } = useMemo(
      () => ({
        hasBackgroundImage: isMobile
          ? !!backgroundImageMobile?.file?.url
          : !!image?.file?.url,
        backgroundImageStyle: {
          width: "100%",
          backgroundImage: isMobile
            ? backgroundImageMobile?.file?.url
              ? `url(${optimizeBackgroundImageUrl(
                  backgroundImageMobile?.file?.url,
                  window.innerWidth,
                )})`
              : null
            : image?.file?.url
              ? optimizeBackgroundImageUrl(image?.file?.url, 1500)
              : null,
          aspectRatio: isMobile
            ? backgroundImageMobile?.file?.url
              ? backgroundImageMobile?.file?.details?.image?.width /
                backgroundImageMobile?.file?.details?.image?.height
              : null
            : image?.file?.url
              ? image?.file?.details?.image?.width /
                image?.file?.details?.image?.height
              : null,
        },
      }),
      [image],
    );

    return (
      <div
        id={id}
        ref={ref}
        className={cx(announcementCardVariants({ variant }), {
          [styles.pointer]: !!(actionUrl || announcementUrl),
          [styles.hasBackgroundImage]: hasBackgroundImage,
          [styles.noDots]: noDots,
        })}
        style={backgroundImageStyle}
      >
        <div className={styles.content} ref={contentRef}>
          <div className={styles.title}>{title}</div>
          <div className={styles.message}>
            {message?.childMarkdownRemark?.html && (
              <ContentfulHtmlRenderer
                htmlContent={message?.childMarkdownRemark?.html}
              />
            )}
            {eventId && (
              <>
                {" "}
                {event?.eventName ?? " "}&nbsp;
                {event?.scheduledStartTime && <span>&#x2022; </span>}
                {eventTime}
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
);

AnnouncementCard.displayName = "announcementCard";

enum RenderStates {
  firstRender = 0,
  secondRender = 1,
  afterSwipeRender = 2,
}

export const AnnouncementCards = ({
  page,
  className,
}: AnnouncementCardProps) => {
  const cards = useAnnouncementCards(page);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const draggingRef = useRef(false);
  const [direction, setDirection] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [renderState, setRenderState] = useState<RenderStates>(
    RenderStates.firstRender,
  );

  const variants = {
    enter: { x: direction < 0 ? "100%" : "-100%", opacity: 0 },
    center: { zIndex: 1, x: 0, opacity: 1 },
    exit: { zIndex: 0, x: direction > 0 ? "100%" : "-100%", opacity: 0 },
  };

  // preload background images
  useEffect(() => {
    cards.forEach((card) => {
      if (card?.image?.file?.url) {
        const image = new Image();
        image.src = optimizeBackgroundImageUrl(
          card?.image?.file?.url,
          window.innerWidth,
        );
      }
      if (card?.backgroundImageMobile?.file?.url) {
        const mobileImage = new Image();
        mobileImage.src = optimizeBackgroundImageUrl(
          card?.backgroundImageMobile?.file?.url,
          window.innerWidth,
        );
      }
    });
  }, []);

  const handleDotClick = (index) => {
    if (!draggingRef.current) {
      setCurrentCardIndex(index);
    }
  };

  const handleDragStart = () => {
    if (cards.length > 1 && !draggingRef.current) draggingRef.current = true;
  };

  const handleDragEnd = (_, info) => {
    if (cards.length > 1) {
      setTimeout(() => {
        draggingRef.current = false;
      }, 50);

      if (!info || !info.velocity) return;

      if (info.velocity.x > 0) {
        setDirection(1);
        setCurrentCardIndex((prev) => Math.max(prev - 1, 0));
      } else {
        setDirection(-1); // setting direction to -1 for left swipe
        setCurrentCardIndex((prev) => Math.min(prev + 1, cards.length - 1));
      }
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement; // Explicitly cast e.target to HTMLElement

    // if target is an anchor tag, don't navigate
    if (target?.tagName === "A") return;

    if (!draggingRef.current) {
      if (
        cards[currentCardIndex]?.announcementUrl ||
        cards[currentCardIndex]?.actionUrl
      ) {
        const actionUrl =
          cards[currentCardIndex]?.announcementUrl?.internal?.content ??
          cards[currentCardIndex]?.actionUrl;
        try {
          const url = new URL(actionUrl);
          PICKLEBET_DOMAINS.some((domain) => actionUrl.includes(domain))
            ? navigate(`${url.pathname}${url.search}${url.hash}`)
            : (window.location.href = actionUrl);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const updateHeight = () => {
    const element = document.querySelector(
      `[id='${cards[currentCardIndex]?.id}']`,
    ) as HTMLElement;

    setContainerHeight(element?.offsetHeight);
  };

  useEffect(() => {
    updateHeight();
  }, [currentCardIndex]);

  useEffect(() => {
    setCurrentCardIndex(0);
    updateHeight();
  }, [cards.length]);

  useEffect(() => {
    setRenderState(RenderStates.secondRender);
  }, []);

  const enableTransition = () => {
    setRenderState(RenderStates.afterSwipeRender);
  };

  if (!cards.length) return null;

  return (
    <ErrorBoundary>
      <div
        className={cx(
          styles.announcementCards,
          {
            [styles.hasRendered]: renderState >= 2,
          },
          className,
        )}
        style={{ height: containerHeight }}
      >
        <AnimatePresence
          initial={false}
          custom={direction}
          onExitComplete={enableTransition}
        >
          <motion.div
            key={cards[currentCardIndex]?.id}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onClick={handleClick}
            className={styles.motionDivClass}
            initial="enter"
            animate="center"
            exit="exit"
            custom={direction}
            variants={variants}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <AnnouncementCard
              {...cards[currentCardIndex]}
              key={cards[currentCardIndex]?.id}
              noDots={cards.length <= 1}
            />
          </motion.div>
        </AnimatePresence>
        {cards.length > 1 && (
          <div
            className={cx(styles.dotContainer, {
              [styles.fadeIn]: renderState >= 1,
            })}
          >
            {cards.map((card, index) => (
              <span
                key={card?.id}
                onClick={() => handleDotClick(index)}
                className={cx(styles.dot, {
                  [styles.active]: index === currentCardIndex,
                })}
              ></span>
            ))}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
