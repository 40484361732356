import React from "react";
import { Avatar } from "components/Avatar";
import { Skeleton } from "components/Skeleton";
import cx from "classnames";

import * as styles from "./QuickBalance.module.scss";

type QuickBalanceProps = React.HTMLAttributes<HTMLDivElement> & {
  available: string;
  promo: string;
  name?: string;
  showAvatar?: boolean;
  loading?: boolean;
  className?: string;
  avatarSrc?: string;
};

const QuickBalanceSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.avatar} shape={`circle`} mode="light" />
      <span className={styles.balance}>
        <span>
          <Skeleton className={styles.text} edge={`hard`} mode="light" />
        </span>
        <span className={styles.promo}>
          <Skeleton className={styles.text} edge={`hard`} mode="light" />
        </span>
      </span>
    </div>
  );
};

const QuickBalance = ({
  name,
  available,
  promo,
  showAvatar = true,
  loading = false,
  className,
  avatarSrc = "",
}: QuickBalanceProps) => {
  if (loading) {
    return <QuickBalanceSkeleton />;
  }

  const showPromo = promo !== "$0.00";

  return (
    <div className={cx(styles.container, className)}>
      {showAvatar && (
        <Avatar alt={name} name={name} size={28} src={avatarSrc} />
      )}
      <span
        className={cx(styles.balance, {
          [styles.balanceWithoutPromo]: !showPromo,
        })}
      >
        <span>{available}</span>
        {showPromo && <span className={styles.promo}>{promo}</span>}
      </span>
    </div>
  );
};

export { QuickBalance };
