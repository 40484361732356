import { useStaticQuery, graphql } from "gatsby";

import { useContext, useEffect, useState } from "react";
import { useLocation } from "./useLocation";
import { FirebaseContext } from "context/Firebase";
import { useCanViewPromotions } from "hooks";
import { useInterval } from "usehooks-ts";

export type AnnouncementCardType = {
  id: string;
  showOnRacing?: boolean;
  showOnESports?: boolean;
  showOnSports?: boolean;
  title?: string;
  campaignIds?: string[];
  showOnSpecificUrls?: URLType;
  eventId?: string;
  message?: {
    childMarkdownRemark?: {
      html?: string;
    };
  };
  actionUrl?: string;
  announcementUrl?: {
    internal?: {
      content?: string;
    };
  };
  image?: {
    file?: {
      url?: string;
      details?: {
        image?: {
          width?: number;
          height?: number;
        };
      };
    };
  };
  backgroundImageMobile?: {
    file?: {
      url?: string;
      details?: {
        image?: {
          width?: number;
          height?: number;
        };
      };
    };
  };
  variant: "success" | "danger" | "warning" | "info" | "neutral";
  active?: boolean;
  promotional?: boolean;
  expiryDate?: Date;
  startTime?: Date;
};

type URLType = {
  url: string;
};

export type AnnouncementCardProps = {
  page?: "racing" | "sports" | "esports";
  className?: string;
};

export const useAnnouncementCards = (
  page?: AnnouncementCardProps["page"],
): AnnouncementCardType[] => {
  const { pathname } = useLocation();
  const { campaigns } = useContext(FirebaseContext);
  const canViewPromotions = useCanViewPromotions();
  const [filteredCards, setFilteredCards] = useState<AnnouncementCardType[]>(
    [],
  );

  const result = useStaticQuery(graphql`
    {
      allContentfulAnnouncementCard {
        edges {
          node {
            id
            createdAt
            updatedAt
            showOnRacing
            showOnESports
            showOnSports
            title
            campaignIds
            eventId
            active
            actionUrl
            announcementUrl {
              internal {
                content
              }
            }
            showOnSpecificUrls {
              url
            }
            message {
              childMarkdownRemark {
                html
              }
            }
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            backgroundImageMobile {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            variant
            promotional
            expiryDate
            startTime
          }
        }
      }
    }
  `);

  const updateFilteredCards = () => {
    setFilteredCards(
      result.allContentfulAnnouncementCard.edges
        .map((edge) => {
          const card = edge.node;

          return {
            ...card,
            startTime: card.startTime ? new Date(card.startTime) : null,
            expiryDate: card.expiryDate ? new Date(card.expiryDate) : null,
          };
        })
        .filter((card) => {
          if (!card?.active) return false;
          if (card?.promotional && !canViewPromotions) return false;

          if (card?.campaignIds?.length > 0) {
            const useHasAtLeast1Campaign = card.campaignIds.some((campaignId) =>
              campaigns.some((campaign) => campaign === campaignId),
            );
            if (!useHasAtLeast1Campaign) return false;
          }

          if (card?.expiryDate) {
            const now = new Date();
            if (card?.expiryDate < now) return false;
          }

          if (card?.startTime) {
            const now = new Date();
            if (card.startTime > now) return false;
          }

          if (page === "racing" && card?.showOnRacing) return true;
          if (page === "esports" && card?.showOnESports) return true;
          if (page === "sports" && card?.showOnSports) return true;

          if (card?.showOnSpecificUrls) {
            try {
              const url = new URL(card?.showOnSpecificUrls.url);
              const regExPattern = `^${url.pathname
                // Escape any periods in the URL
                .replace(/\./g, "\\.")
                // Replace any asterisks with regex wildcard
                .replace(/\*/g, ".*")}$`;
              const regEx = new RegExp(regExPattern);
              if (regEx.test(pathname)) return true;
            } catch (e) {
              console.error(e);
            }
          }

          return false;
        })
        .sort((a, b) => {
          // sort by start time oldest first
          if (a.startTime && b.startTime) {
            return a.startTime.getTime() - b.startTime.getTime();
          }

          if (a.startTime && !b.startTime) {
            return -1;
          }

          if (!a.startTime && b.startTime) {
            return 1;
          }

          return 0;
        }),
    );
  };

  useInterval(() => {
    updateFilteredCards();
  }, 1000 * 60); // 1 minute

  useEffect(() => {
    updateFilteredCards();
  }, []);

  return filteredCards;
};
