import { yupResolver } from "@hookform/resolvers/yup";
import { Toggle } from "components/Toggle";
import { Form } from "components/Form";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import type { BetEntry } from "sections/Entries/types";

import { ShareEntryPreview } from "./ShareEntryPreview";

import * as styles from "./ShareEntryForm.module.scss";

const schema = Yup.object({
  title: Yup.string().required(),
  displayStake: Yup.boolean().required(),
});

export type ShareEntryFormSchema = Yup.InferType<typeof schema>;

type ShareEntryFormProps = {
  username: string;
  onSubmit: (values: ShareEntryFormSchema) => Promise<void>;
  onFieldTouched: () => void;
  submitButton: (loading: boolean) => React.ReactNode;
  entry?: [BetEntry, boolean];
};

export const ShareEntryForm = ({
  username,
  onSubmit,
  onFieldTouched,
  submitButton,
  ...props
}: ShareEntryFormProps) => {
  const [entry, isPreviewLoading] = props.entry;

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<ShareEntryFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: `${username}'s shared bet`,
      displayStake: true,
    },
  });

  const handleInputChange = (
    field: keyof ShareEntryFormSchema,
    value: ShareEntryFormSchema[keyof ShareEntryFormSchema],
  ) => {
    onFieldTouched?.();
    setValue(field, value);
  };

  return (
    <Form
      noSpacing
      className={styles.shareForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      {!!entry && (
        <ShareEntryPreview
          entry={{
            ...entry,
            hideStake: !watch("displayStake"),
          }}
          isLoading={isPreviewLoading}
        />
      )}

      <Toggle
        id="displayStake"
        {...register("displayStake")}
        label="Display stake information"
        disabled={isSubmitting}
        checked={watch("displayStake")}
        onCheckedChange={(checked) =>
          handleInputChange("displayStake", checked)
        }
        className={styles.toggle}
        labelRight
        noSpacing
      />
      <div>{submitButton(isSubmitting)}</div>
    </Form>
  );
};
