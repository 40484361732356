import React from "react";
import { AnnouncementCards } from "components/AnnouncementCards";
import { PageContent } from "components/Page";
import type { RacingRouteParams } from "sections/Betting/Racing/hooks/useRacingRoute";
import {
  DateSelector,
  NextToJump,
  RacesByDay,
} from "sections/Betting/Racing/components";
import { useRaceMeetings } from "sections/Betting/Racing/hooks/useRaceMeetings";
import { RaceMeetingsFilter } from "../RaceMeetingsFilter";

import * as styles from "./RaceMeetings.module.scss";

export const titleSportMapping = {
  HORSE_RACING: "horse_racing",
  GREYHOUNDS: "greyhounds",
  HARNESS_RACING: "harness_racing",
};

export type RaceMeetingsProps = {
  setRouteValue: (arg: Partial<RacingRouteParams>) => void;
  route: RacingRouteParams;
};

export const RaceMeetings = (props: RaceMeetingsProps) => {
  const { titleCounts, route } = useRaceMeetings(props);

  return (
    <>
      <DateSelector {...props} />
      <RaceMeetingsFilter titleCounts={titleCounts} {...props} />
      <PageContent className={styles.page}>
        <AnnouncementCards page="racing" />
        {route.tab === "next-to-jump" ? (
          <NextToJump {...props} />
        ) : (
          <RacesByDay {...props} />
        )}
      </PageContent>
    </>
  );
};
