import React from "react";
import { useUserProgression } from "hooks/useUserProgression";
import cx from "classnames";
import { Skeleton } from "components/Skeleton";
import { SmallPromoIcon } from "components/PromoIcon/SmallPromoIcon";
import { defaultHelpLink } from "sections/Betting/CampaignPromotions";
import { Link } from "components/Link";
import { Clamp } from "components/Clamp";
import { useIsMobile } from "hooks";
import type { BetEntry } from "sections/Entries/types";

import * as styles from "./BetPickAutomation.module.scss";

type BetPickAutomationProps = {
  isWon: boolean;
  entry: BetEntry;
  progressionId: string;
};

const BetPickAutomation = ({
  isWon,
  progressionId,
  entry,
}: BetPickAutomationProps) => {
  const isMobile = useIsMobile();

  const {
    campaign,
    isComplete,
    isFailed,
    isPromo,
    loading,
    displayPayout,
    progression,
    hide,
  } = useUserProgression(progressionId, entry);

  if (hide) return null;

  return (
    <div
      className={cx(styles.promotionContainer, {
        [styles.isWon]: isWon,
        [styles.loading]: loading || !progression,
      })}
    >
      {loading || !progression ? (
        <Skeleton
          className={cx(styles.skeleton, { [styles.isMobile]: isMobile })}
        />
      ) : (
        <>
          <SmallPromoIcon className={styles.promoIcon} />
          <div className={styles.heading}>
            <div>{campaign?.name}</div>
            <a href={campaign?.termsOfUse ?? defaultHelpLink}>View Terms</a>
          </div>
          <div className={styles.content}>{campaign?.description}</div>
          <div className={styles.dashedLine} />
          <div className={styles.complete}>
            <div className={styles.payout}>{displayPayout}</div>
            <div className={styles.statusArea}>
              {isComplete && !isFailed && (
                <Link
                  to={`/wallet/statement/${
                    isPromo ? `?accountPurpose=promotion_user` : ``
                  }`}
                >
                  View Statement
                </Link>
              )}
              <div className={styles.clamp}>
                <Clamp variant="warning" className={styles.clampItem}>
                  {isComplete
                    ? isFailed
                      ? `NOT PAID`
                      : `PAID`
                    : `IN PROGRESS`}
                </Clamp>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { BetPickAutomation };
