import { isEmpty } from "lodash";
import {
  exoticMarketTypes,
  type ExoticMarketType,
  type RaceMarketsType,
  type RaceOutcomeType,
} from "sections/Betting/Race/hooks/RacingTypes";
import type { SubOutcome } from "sections/Entries/types";

export const exoticPositionFromOutcomeMap = new Map<
  RaceOutcomeType["type"],
  number
>([
  ["RUNNER_WIN", 1],
  ["RUNNER_POS_2", 2],
  ["RUNNER_TOP_2", 2],
  ["RUNNER_POS_3", 3],
  ["RUNNER_TOP_3", 3],
  ["RUNNER_POS_4", 4],
  ["RUNNER_TOP_4", 4],
]);

const findOutcomesForExotic = (
  type: ExoticMarketType,
  markets: Array<RaceMarketsType>,
) => markets?.find((m) => m.marketType === type)?.outcomes ?? {};

export const getSelectedRunnerNumbers =
  (selections: string[]) => (outcome: RaceOutcomeType) =>
    selections.includes(outcome.id) ? outcome.attributes?.runnerNumber : [];

export const byNumericRunnerPosition = <T extends { type: string }>(
  a: T,
  b: T,
) => {
  const aPosition = exoticPositionFromOutcomeMap.get(a.type) || 0;
  const bPosition = exoticPositionFromOutcomeMap.get(b.type) || 0;
  return aPosition - bPosition;
};

type OutcomeFilterCondition = (outcome: RaceOutcomeType) => boolean;
const createOutcomeFilter =
  (condition: OutcomeFilterCondition) =>
  ([outcomeId, outcome]: [string, RaceOutcomeType]) =>
    condition(outcome)
      ? {
          ...outcome,
          // have to ensure this id is here
          // depsite the type definition it's not always the case
          id: outcomeId,
        }
      : ([] as const);

export const filterByOutcomeType = (outcomeType: RaceOutcomeType["type"]) =>
  createOutcomeFilter((outcome) => outcome.type === outcomeType);

type FilterFn = (
  entry: [string, RaceOutcomeType],
) => RaceOutcomeType | readonly [];

export const pickExoticMarkets =
  (type: ExoticMarketType) =>
  (markets: RaceMarketsType[], filterEntries: FilterFn): RaceOutcomeType[] =>
    Object.entries(findOutcomesForExotic(type, markets)).flatMap(filterEntries);

export const getExoticUtils = (
  currentSelections: string[],
  outcomes: Array<RaceOutcomeType>,
) => {
  const competitiorOutcomeIds = outcomes.map((outcome) => outcome.id);

  const isAnyOutcomeSelected = currentSelections.some((id) =>
    competitiorOutcomeIds.includes(id),
  );
  const isAllOutcomesSelected = competitiorOutcomeIds.every((id) =>
    currentSelections.includes(id),
  );

  const addAllCompetitorOutcomes = (selectedIds: string[]) => [
    ...selectedIds,
    ...competitiorOutcomeIds,
  ];
  const removeAllCompetitorOutcomes = (selectedIds: string[]) =>
    selectedIds.filter((id) => !competitiorOutcomeIds.includes(id));

  const toggleMatching =
    (outcome: (typeof outcomes)[number]) => (selectedIds: string[]) =>
      selectedIds.includes(outcome.id)
        ? selectedIds.filter((id) => id !== outcome.id)
        : selectedIds.concat(outcome.id);

  return {
    isAnyOutcomeSelected,
    isAllOutcomesSelected,
    addAllCompetitorOutcomes,
    removeAllCompetitorOutcomes,
    toggleMatching,
  };
};

export const calculateExoticCombinations = (selections: string[][]): number => {
  const helper = (
    remainingSelections: string[][],
    usedHorses: Set<string>,
  ): number => {
    if (remainingSelections.length === 0) return 1;

    const currentSelection = remainingSelections[0];
    const remaining = remainingSelections.slice(1);
    let count = 0;

    currentSelection.forEach((horse) => {
      if (!usedHorses.has(horse)) {
        const newUsedHorses = new Set(usedHorses);
        newUsedHorses.add(horse);
        count += helper(remaining, newUsedHorses);
      }
    });

    return count;
  };

  return helper(selections, new Set<string>());
};

/** Order isn't considered in quinella combinations */
export const calculateQuinellaCombinations = (
  selections: string[][],
): number => {
  const [firstPlaceSelections, secondPlaceSelections] = selections;

  const usedPairs = new Set<string>();
  let count = 0;

  firstPlaceSelections.forEach((horse1) => {
    secondPlaceSelections.forEach((horse2) => {
      if (horse1 !== horse2) {
        const pair = [horse1, horse2].sort().join("-");
        if (!usedPairs.has(pair)) {
          usedPairs.add(pair);
          count++;
        }
      }
    });
  });

  return count;
};

export const isExoticMarketType = (
  marketName: string,
): marketName is ExoticMarketType => {
  if (!marketName) return false;

  return (
    // marketNames are different to marketTypes, so First_4 in some cases
    // needs to drop the underscore, others can simply be uppercased
    marketName === "First4" ||
    exoticMarketTypes.includes(marketName.toUpperCase() as ExoticMarketType)
  );
};

export const exoticOutcomeFromPositionMap = new Map<
  number,
  RaceOutcomeType["type"]
>([
  [1, "RUNNER_WIN"],
  [2, "RUNNER_POS_2"],
  [2, "RUNNER_TOP_2"],
  [3, "RUNNER_POS_3"],
  [3, "RUNNER_TOP_3"],
  [4, "RUNNER_POS_4"],
  [4, "RUNNER_TOP_4"],
]);

export const addToAccumulator =
  (acc: string[][]) => (pos: number, runner: string) => {
    if (!acc[pos]) {
      acc[pos] = [];
    }
    if (!acc[pos].includes(runner)) {
      acc[pos].push(runner);
    }
  };

/** Works backwards from the outcomes to generate a 2D array of horse numbers
 * indexed to match the position. ie:
 *
 * index 1 is the array for all selected winners
 * index 2 is for all pos 2 or top 2 runners
 * etc..
 * */
export const groupRunnersByPosition = (
  selectedOutcomes: Record<string, SubOutcome>,
): string[][] => {
  return !selectedOutcomes || isEmpty(selectedOutcomes)
    ? []
    : Object.values(selectedOutcomes)
        .sort(byNumericRunnerPosition)
        .reduce((acc, outcome) => {
          const {
            type,
            attributes: { runnerNumber },
          } = outcome;
          const position = exoticPositionFromOutcomeMap.get(type);
          const addRunnerToPosition = addToAccumulator(acc);

          if (!position) return acc;

          if (type === "RUNNER_TOP_2") {
            // this is a quinella edge case
            // top two means both first and second so to need to duplicate the same
            // numbers for first position
            addRunnerToPosition(1, runnerNumber);
          }

          addRunnerToPosition(position, runnerNumber);
          return acc;
        }, []);
};
