import cx from "classnames";
import { OddsChanges } from "components/OddsChanges";
import { ReactComponent as ArrowDown } from "components/assets/flucs-down.svg";
import { ReactComponent as ArrowUp } from "components/assets/flucs-up.svg";
import { FirebaseContext } from "context/Firebase";
import React, { useContext } from "react";
import Odds from "sections/Betting/Odds";

import * as styles from "./SameEventMultiSummary.module.scss";

type SameEventMultiSummaryProps = {
  loading: boolean;
  isLoggedIn: boolean;
  odds: number;
  selectionsLength: number;
};

export const SameEventMultiSummary = ({
  loading,
  isLoggedIn,
  odds,
  selectionsLength,
}: SameEventMultiSummaryProps) => {
  const { userPreferences } = useContext(FirebaseContext);
  return (
    <>
      <div className={styles.text}>
        <div className={styles.label}>LEGS</div>
        <div className={styles.textValue}>{selectionsLength}</div>
      </div>
      <div className={styles.line}>&nbsp;</div>
      {isLoggedIn && (
        <OddsChanges odds={odds} className={cx(styles.text, styles.odds)}>
          <div className={styles.label}>ODDS</div>

          <div
            className={cx(styles.textValue, {
              [styles.oddsChanging]: loading,
            })}
          >
            <Odds
              base={Number(odds)}
              zero={`-`}
              format={userPreferences.oddsFormat}
            />
            {odds > 0 && (
              <>
                <div className={styles.arrowUp}>
                  <ArrowUp />
                </div>

                <div className={styles.arrowDown}>
                  <ArrowDown />
                </div>
              </>
            )}
          </div>
        </OddsChanges>
      )}
    </>
  );
};
