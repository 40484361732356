import React, { useEffect } from "react";
import type { RaceOutcomeType } from "sections/Betting/Race/hooks/RacingTypes";
import { exoticPositionFromOutcomeMap, getExoticUtils } from "./Exotics.utils";
import {
  RunnerPositionToggle,
  RunnerPositionToggleGroup,
} from "./RunnerPositionToggle";
import Odds from "sections/Betting/Odds";

import * as styles from "./Exotics.module.scss";

export type CompetitorsExoticOutcomesProps = {
  outcomes: Array<RaceOutcomeType>;
  currentSelections: Array<string>;
  setCurrentSelections: React.Dispatch<React.SetStateAction<string[]>>;
  isBoxed: boolean;
  isDisabled?: boolean;
  winMarketOdds: string;
};

export const CompetitorsExoticOutcomes = ({
  outcomes,
  currentSelections,
  setCurrentSelections,
  isBoxed,
  isDisabled,
  winMarketOdds,
}: CompetitorsExoticOutcomesProps) => {
  const {
    isAnyOutcomeSelected,
    isAllOutcomesSelected,
    addAllCompetitorOutcomes,
    removeAllCompetitorOutcomes,
    toggleMatching,
  } = getExoticUtils(currentSelections, outcomes);

  useEffect(() => {
    if (isBoxed && isAnyOutcomeSelected) {
      setCurrentSelections(addAllCompetitorOutcomes);
    }
  }, [isBoxed]);

  return isDisabled ? null : (
    <div className={styles.competitorOutcomes}>
      {winMarketOdds ? (
        <Odds className={styles.competitorOdds} base={Number(winMarketOdds)} />
      ) : (
        <div className={styles.competitorOdds}>SP</div>
      )}

      <RunnerPositionToggleGroup
        isBoxed={isBoxed}
        isBoxedActive={!isDisabled && isAllOutcomesSelected}
        onBoxedChange={(isActive) =>
          isActive
            ? setCurrentSelections(addAllCompetitorOutcomes)
            : setCurrentSelections(removeAllCompetitorOutcomes)
        }
      >
        {outcomes.map((outcome) => {
          return (
            <RunnerPositionToggle
              key={outcome.id}
              isActive={currentSelections.includes(outcome.id)}
              onClick={() => setCurrentSelections(toggleMatching(outcome))}
            >
              {exoticPositionFromOutcomeMap.get(outcome.type)}
            </RunnerPositionToggle>
          );
        })}
      </RunnerPositionToggleGroup>
    </div>
  );
};
