import cx from "classnames";
import { useBetslip } from "hooks";
import React from "react";
import { BonusBadge } from "./BonusBadge";
import { CardFooter } from "./CardFooter";
import { OddsWithChanges } from "./OddsWithChanges";
import { Payout } from "./Payout";
import { Stake } from "./Stake";

import * as styles from "./Summary.module.scss";

export type SummaryProps = {
  odds: number;
  payout: number;
  stake: number;
  onSetStake: (stake: number) => void;
  isPromo: boolean;
  isActive: boolean;
  newOdds: number;
  isSP: boolean;
  isMulti?: boolean;
  exoticCombinations?: number;
};

export const BetCardSummary = ({
  stake,
  payout,
  odds,
  onSetStake,
  isPromo,
  isActive,
  newOdds,
  isSP,
  isMulti,
}: SummaryProps) => {
  const {
    props: { betslipIsReviewingMessages },
  } = useBetslip();

  const currentOdds = newOdds ?? odds;

  return (
    <CardFooter.Container>
      <CardFooter.Section
        className={cx({
          [styles.isPromo]: isPromo,
          [styles.stake]: !stake && !betslipIsReviewingMessages && isActive,
        })}
        label="Stake"
      >
        <Stake
          isActive={isActive}
          onChange={onSetStake}
          amount={stake}
          isPromo={isPromo}
        />
        {isPromo && <BonusBadge />}
      </CardFooter.Section>
      <CardFooter.Section label="Odds">
        <OddsWithChanges
          isSP={isMulti ? false : isSP}
          odds={currentOdds}
          animationLength={isMulti ? 0 : newOdds ? null : 3000}
        />
      </CardFooter.Section>
      <CardFooter.Section label="Payout">
        <Payout isSP={isSP} amount={payout} />
      </CardFooter.Section>
    </CardFooter.Container>
  );
};
