import { Typography } from "components/Typography";
import { useSelector } from "hooks";
import React, { type SetStateAction } from "react";
import { selectIsMultiOutcomeInBetslip } from "sections/Betting/Betslip/betslipSlice";
import {
  ComplexBetBuilder,
  type ComplexBetBuilderProps,
} from "sections/Betting/components";

import * as styles from "./ExoticBetBuilder.module.scss";

type ExoticBetBuilderProps = Omit<
  ComplexBetBuilderProps,
  "type" | "handleRemoveSelection" | "handleRemoveAllSelections"
> & {
  selections: string[];
  setSelections: React.Dispatch<SetStateAction<string[]>>;
  children: React.ReactNode;
  combinations: number;
};

export const ExoticCombinations = ({
  combinations,
}: { combinations: number }) => {
  return (
    <div className={styles.combos}>
      <Typography noSpacing>COMBOS</Typography>
      <Typography noSpacing className={styles.comboCount}>
        {combinations}
      </Typography>
    </div>
  );
};

export const ExoticBetBuilder = ({
  selections,
  setSelections,
  combinations,
  children,
  ...props
}: ExoticBetBuilderProps) => {
  const handleRemoveSelection = (outcomeId: string) => {
    setSelections((selectedIds) =>
      selectedIds.filter((id) => id !== outcomeId),
    );
  };

  const handleRemoveAllSelections = () => {
    setSelections([]);
  };

  const isExoticSelectionInBetslip = useSelector(selectIsMultiOutcomeInBetslip)(
    selections,
  );

  return (
    <ComplexBetBuilder
      type="exotic"
      selections={selections}
      handleRemoveSelection={handleRemoveSelection}
      handleRemoveAllSelections={handleRemoveAllSelections}
      errorMessage={
        selections.length === 0
          ? "0 Combos"
          : isExoticSelectionInBetslip
            ? "Selected Exotic in your Betslip"
            : null
      }
      errorMessageToReplaceFooter={
        selections.length === 0 || isExoticSelectionInBetslip
      }
      errorMessageShouldAllowClear={isExoticSelectionInBetslip}
      renderSlipInfo={() => <ExoticCombinations combinations={combinations} />}
      /**
       * selections in this context refers to required combinations for exotics
       */
      requiredSelectionCount={1}
      selectionsLengthOverride={combinations}
      {...props}
    >
      {children}
    </ComplexBetBuilder>
  );
};
