import React from "react";
import { constructCurrency } from "common";
import { useFiatCurrency } from "hooks";

type PayoutProps = {
  amount: number;
  isSP?: boolean;
};

const Payout = ({ amount, isSP = false }: PayoutProps) => {
  const currency = useFiatCurrency();

  if (isSP) {
    return <div>N/A</div>;
  }

  return (
    <div>
      {constructCurrency(amount || 0, {
        currencyType: currency,
      })}
    </div>
  );
};

export { Payout };
