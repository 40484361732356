// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "ExoticSelections-module--emphasis--925eb";
export var exoticRunners = "ExoticSelections-module--exoticRunners--ac00b";
export var exoticSelections = "ExoticSelections-module--exoticSelections--bd933";
export var green = "#57d33b";
export var greenLink = "ExoticSelections-module--green-link--4632e";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var runnerNumber = "ExoticSelections-module--runnerNumber--2bea3";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";