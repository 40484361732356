import React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import cx from "classnames";
import * as styles from "./ScrollArea.module.scss";

type ScrollAreaProps = {
  hideScrollbars?: boolean;
  viewPortRef?: React.Ref<HTMLDivElement>;
  darkScrollBar?: boolean;
  viewPortClassName?: string;
  hideHorizontalScrollbar?: boolean;
  hideVerticalScrollbar?: boolean;
  viewPortStyles?: React.CSSProperties;
  horizontalScrollBarClassName?: string;
} & React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>;

type ScrollBarProps = {
  isInvisible?: boolean;
  darkScrollBar?: boolean;
} & React.ComponentPropsWithoutRef<
  typeof ScrollAreaPrimitive.ScrollAreaScrollbar
>;

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  ScrollAreaProps
>(
  (
    {
      className,
      children,
      asChild = false,
      hideScrollbars = false,
      darkScrollBar = false,
      viewPortRef,
      viewPortClassName,
      hideVerticalScrollbar,
      hideHorizontalScrollbar,
      viewPortStyles,
      horizontalScrollBarClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <ScrollAreaPrimitive.Root
        ref={ref}
        className={cx(styles.container, className)}
        {...props}
      >
        <ScrollAreaPrimitive.Viewport
          ref={viewPortRef}
          className={cx(styles.viewport, viewPortClassName)}
          asChild={asChild}
          style={viewPortStyles}
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar
          orientation={`horizontal`}
          darkScrollBar={darkScrollBar}
          isInvisible={hideScrollbars || hideHorizontalScrollbar}
          className={horizontalScrollBarClassName}
        />
        <ScrollBar
          orientation={`vertical`}
          darkScrollBar={darkScrollBar}
          isInvisible={hideScrollbars || hideVerticalScrollbar}
        />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  },
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  ScrollBarProps
>(
  (
    {
      className,
      orientation = "vertical",
      darkScrollBar,
      isInvisible,
      ...props
    },
    ref,
  ) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cx(
        styles.scrollBar,
        {
          [styles.vertical]: orientation === "vertical",
          [styles.horizontal]: orientation === "horizontal",
          [styles.invisible]: isInvisible,
        },
        className,
      )}
      {...props}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb
        className={cx(styles.thumb, {
          [styles.dark]: darkScrollBar,
        })}
      />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  ),
);
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
