import { usePreviousValue } from "hooks";
import { mapValues } from "lodash";
import { useState } from "react";
import type { RaceOutcomeType } from "sections/Betting/Race/hooks/RacingTypes";
import { useBoolean } from "usehooks-ts";

export const useExoticOutcomeSelections = (
  groupedOutcomes: Record<string, RaceOutcomeType[]>,
) => {
  const [selectedOutcomes, setSelectedOutcomes] = useState<string[]>([]);
  const previouslySelectedOutcomes = usePreviousValue(selectedOutcomes);

  const groupedOutcomeIds = mapValues(groupedOutcomes, (outcomes) =>
    outcomes.map((o) => o.id),
  );

  const handleSelectedOutcomeGroup = (type: string) => {
    const outcomes = groupedOutcomeIds[type];

    if (outcomes.some((id) => selectedOutcomes.includes(id))) {
      setSelectedOutcomes((p) => p.filter((id) => !outcomes.includes(id)));
      return;
    }

    setSelectedOutcomes((p) => p.concat(outcomes));
  };

  const outcomes = Object.values(groupedOutcomes).flat();

  const { value: isBoxed, setValue: setIsBoxed } = useBoolean();
  const isBoxedActive = outcomes.every((o) => selectedOutcomes.includes(o.id));
  const onBoxedChange = (isActive: boolean) =>
    isActive
      ? setSelectedOutcomes(outcomes.map((o) => o.id))
      : setSelectedOutcomes([]);

  return {
    selectedOutcomes,
    setSelectedOutcomes,
    isBoxed,
    setIsBoxed,
    isBoxedActive,
    onBoxedChange,
    handleSelectedOutcomeGroup,
    previouslySelectedOutcomes,
  } as const;
};
