import React from "react";
import { NavLogo } from "../Navigation";
import { RegionMenu } from "../RegionMenu";
import DownloadApp from "./DownloadApp";
import { AboutLinks, LegalLinks } from "sections/Account/Links";
import FooterLink from "./FooterLink";
import {
  SVG_ESIC,
  SVG_PLUS_18,
  SVG_ICON_FACEBOOK,
  SVG_ICON_TWITTER,
  SVG_ICON_INSTAGRAM,
  SVG_ICON_TIKTOK,
} from "assets/icons";
import { ReactComponent as Logo } from "assets/logo.svg";
import {
  useIsBettingSection,
  useDisplayAtMost,
  useDisplayMode,
  useDisplayAtLeast,
} from "hooks";
import { useIsClient } from "usehooks-ts";

import * as styles from "./Footer.module.scss";
import ResponsibleGambling from "../ResponsibleGambling";
import { SACodeOfPractice } from "./SACodeOfPractice";

const Footer = () => {
  const displayMode = useDisplayMode();
  const isTabletAndBelow = useDisplayAtMost("tablet");
  const isTablet = displayMode === "tablet";
  const isDesktopAndAbove = useDisplayAtLeast("desktop");
  const isClient = useIsClient();
  const isBettingSection = useIsBettingSection();

  if (isClient && isTabletAndBelow && !isBettingSection) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.expanded}>
        <div className={styles.section}>
          <NavLogo />
          <RegionMenu />
          <DownloadApp />
        </div>
        <div className={styles.section}>
          <h3>Info</h3>
          <p>
            Licensed and regulated by Australia's Northern Territory Government.
          </p>
          {isDesktopAndAbove && (
            <p>
              <SACodeOfPractice />
            </p>
          )}
          <p>
            Puntaa Pty Ltd trading as Picklebet. Unit 1, 48-50 Smith Street
            Darwin NT 0800.
          </p>
          <ResponsibleGambling />
        </div>
        <div className={styles.section}>
          <h3>Legal</h3>
          {LegalLinks.map((link) => (
            <FooterLink key={link.name} link={link} />
          ))}
        </div>
        <div className={styles.section}>
          <h3>Help</h3>
          {AboutLinks.map((link) => (
            <FooterLink key={link.name} link={link} />
          ))}
        </div>
      </div>
      <hr />

      <div className={styles.compact}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.social}>
          <a
            href={`https://www.facebook.com/picklebet`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVG_ICON_FACEBOOK />
          </a>

          <a
            href={`https://twitter.com/picklebet`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVG_ICON_TWITTER />
          </a>

          <a
            href={`https://www.instagram.com/picklebet/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVG_ICON_INSTAGRAM />
          </a>

          <a
            href={`https://www.tiktok.com/@picklestudios?lang=en`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVG_ICON_TIKTOK />
          </a>

          <SVG_PLUS_18 />

          <a
            href={`https://esic.gg/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVG_ESIC />
          </a>
        </div>
        <div className={styles.linksWrapper}>
          <div className={styles.links}>
            <FooterLink
              link={{
                name: "Terms & Conditions",
                supportOptions: { articleId: "115009926727" },
              }}
            />
            <FooterLink
              link={{
                name: "Responsible Gambling",
                supportOptions: { articleId: "115010094608" },
              }}
            />
          </div>
          <div className={styles.sacop}>
            <SACodeOfPractice />
          </div>
        </div>
      </div>
      {isTabletAndBelow && <ResponsibleGambling />}
    </div>
  );
};

export default Footer;
