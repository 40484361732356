import { useEffect, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";

/**
 * A hook that tracks how far an element has been scrolled, useful for
 * displaying dynamic elements such as shadows to indicate that an element is
 * scrollable
 */
export const useScrollBoundary = () => {
  const viewportRef = useRef<HTMLDivElement>(null);

  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  const onScroll = () => {
    // this method is called when tab scroller is scrolled
    const scroller = viewportRef.current;
    const isStart = scroller.scrollLeft <= 0;
    const isEnd =
      Math.ceil(scroller.scrollLeft) + scroller.offsetWidth >=
      scroller.scrollWidth;

    const isScrollable = scroller.scrollWidth > scroller.offsetWidth;

    setIsStart(isStart);
    setIsEnd(isEnd);
    setIsScrollable(isScrollable);
  };

  useEffect(() => {
    // make sure we calculate initial scrollable values before any scroll happens
    if (!viewportRef.current) return;
    onScroll();
  }, []);

  useEventListener("scroll", onScroll, viewportRef);

  return { viewportRef, isStart, isEnd, isScrollable } as const;
};
